import "./App.css";

import intl from "react-intl-universal";
import React, { useState, useEffect, useCallback } from "react";
import { Layout, Row, Col, Spin } from "antd";

import zhCN from './locales/zh-CN.json';
import enUS from './locales/en-US.json';

import ServerRow from "./ServerRow";

const { Header, Footer, Content } = Layout;

const LOCALE_DATA = {
  "en-US": enUS,
  "zh-CN": zhCN,
};

const App: React.FC<any> = () => {
  const [serverData, setServerData] = useState({ servers: [], updated: "0" });
  const [isOnline, setIsOnline] = useState(false);
  const [initDone, setInitDone] = useState(false);

  const setCurrentLocale = (currentLocale: string) => {
    intl.init({
      // debug: true,
      currentLocale,
      locales: LOCALE_DATA,
    });
  };

  const initializeIntl = useCallback(() => {
    if (initDone) {
      return
    }
    // 1. URL, çerez veya tarayıcı ayarlarından currentLocale'yi alın
    let currentLocale = intl.determineLocale({
      fallbackLocale: 'en-US',
    });

    // 2. currentLocale LOCALES_LIST'de desteklenmiyorsa "en-US" olarak geri dönün
    if (currentLocale.startsWith("zh-")) {
      currentLocale = "zh-CN";
    } else {
      currentLocale = "en-US";
    }

    // 3. currentLocale'i ayarlayın ve yerel verileri yükleyin
    setCurrentLocale(currentLocale);

    // 4. Yerel verileri yükledikten sonra render etmeye başlayın
    setInitDone(true);
  }, [initDone])
  

  useEffect(() => {
    initializeIntl()
    const fetchData = () => {
      fetch("json/stats.json")
        .then((res) => res.json())
        .then((data) => {
          setServerData(data);
          setIsOnline(true);
        })
        .catch((e) => console.log("Hata:", e));
    };
    fetchData();
    let itv = setInterval(fetchData, 1000);
    return () => {
      clearInterval(itv);
    };
  }, [initializeIntl]);

  return (
    <div className="App">
      {initDone && (<Layout>
        <Header>
          <div className="logo"><img src="logo.png"></img>&nbsp; SUNUCU DURUM BİLGİLERİ</div>
        </Header>
        <Content style={{ background: "#222222" }}>
          <Row justify="center">
            <Col xs={24} sm={23} md={23} lg={22} xl={20} xxl={16}>
              {initDone ? (
                <Spin size="large" spinning={!isOnline} tip="Yükleniyor...">
                  <ServerRow {...serverData} />
                </Spin>
              ) : (
                  <div />
                )}
            </Col>
          </Row>
        </Content>
        <Footer className="footer">
          <a
            href="https://github.com/barkeser2002/"
            rel="external noopener"
          >
            Yapımcı Barış Keser
          </a>
        </Footer>
      </Layout>
      )}
    </div>
  );
};

export default App;
